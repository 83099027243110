import React from 'react'
import './Footer.scss'
import { Link } from 'react-router-dom';

const onButtonClick = () => {
    // using Java Script method to get PDF file
    fetch(process.env.PUBLIC_URL + '/Scan-Moebel-Katalog.pdf').then(response => {
        console.log(process.env.PUBLIC_URL + '/Scan-Moebel-Katalog.pdf');
        response.blob().then(blob => {
            // Creating new object of PDF file
            const fileURL = window.URL.createObjectURL(blob);
            // Setting various property values
            let alink = document.createElement('a');
            alink.href = fileURL;
            alink.download = 'Scan-Moebel-Katalog.pdf';
            alink.click();
        })
    })
}

const Footer = () => {
    const currentYear = new Date().getFullYear().toString();

    return (
        <div className="footer">
            <div className="top">
                <div className="item">
                    <div className='footer_h1'>So erreichen Sie uns</div>
                    <span>Egal wozu Sie Fragen haben, rufen Sie uns gerne an oder schreiben Sie uns eine Mail.</span>
                    <span>038355 6880</span>
                    <span>info@scan-zuessow.de</span>
                    <span>Mo - Fr, 09:30 - 18:00 Uhr</span>
                    <span>Sa, 09:30 - 13:00 Uhr</span>
                    <span>Jeden ersten Samstag im Monat und an allen Adventssamstagen: 09:30 - 16:00 Uhr</span>
                </div>
                <div className="item">
                    <div className='footer_h1'>Impressum</div>
                    <span>Scan Möbel Züssow</span>
                    <span>Chausseestraße 5, 17495 Züssow</span>
                    <span>Inhaber Jürgen Hörnschemeyer</span>
                    <span>UST-ID: DE 811355651</span>
                </div>
                <div className="item">
                    <div className='footer_h1'>Links</div>
                    <Link className='link' to={'/privacy-policy'}>Datenschutz</Link>                 
                    <a className="catalog-download" onClick={onButtonClick}>Katalog Download</a>
                </div>
            </div>
            <div className="bottom">
                <div className="left">
                    <span className='logo'>Scan Möbel Züssow</span>
                    <span className="copyright">v. 1.0.1 {currentYear}</span>
                </div>
                <div className="right">
                    {/* <img src="./images/payments.png" alt="" /> */}
                </div>
            </div>
        </div>
    )
}

export default Footer