import { Link } from 'react-router-dom';
import './Header.scss';
import SearchIcon from '@mui/icons-material/Search';
// import InputBase from '@mui/material/InputBase';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import SearchBar from './SearchBar';
import { Context } from '../App';

const Header = (props) => {
    const [searchInput, setSearchInput] = useContext(Context);

    const handleTabClick = () => {
        setSearchInput("")
    }

    return (
        <div className="header">
            <div className="wrapper">
                <div className="left">
                    <div className="logo">
                        <Link onClick={handleTabClick} className='link' to={'/'}>NATURA HOME</Link>
                        <Link onClick={handleTabClick} className='link2' to={'/'}>by Scan Möbel</Link>
                    </div>
                    <div className="item">
                        <Link onClick={handleTabClick} className='link' to="/products/wohnen">Wohnen</Link>
                    </div>
                    <div className="item">
                        <Link onClick={handleTabClick} className='link' to="/products/schlafen">Schlafen</Link>
                    </div>
                    <div className="item">
                        <Link onClick={handleTabClick} className='link' to="/products/essen">Essen</Link>
                    </div>
                </div>
                <div className="center">
                </div>
                <div className="right">
                    <div>
                        <SearchBar allItems={props.allItems} />
                    </div>
                    {/* <div className="item">
                        <Link className='link' to={'/'}>Homepage</Link>
                    </div> */}
                    {/* <div className="item">
                        <Link className='link' to={'/'}>So finden Sie uns</Link>
                    </div> */}
                    {/* <div className="icon">
                        <div className="emailIcon" ref={popupRef} onClick={() => setEmailClicked(!emailClicked)}>
                            <EmailOutlinedIcon />
                        </div>
                        <div className="phoneIcon" ref={popupRef} onClick={() => setPhoneClicked(!phoneClicked)}>
                            <CallOutlinedIcon />
                            <span>♡</span>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default Header