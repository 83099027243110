import React from "react";
import './FilterOverlay.scss';
import { useState } from "react";


const FilterOverlay = (props) => {
    const highestPrice = props.highestPrice;
    const insertDot = props.formattingFunctions.insertDot;
    const naturaHomeData = props.allItems;
    const removeCurrency = props.formattingFunctions.removeCurrency;
    const getFiltersFromOverlay = props.getFiltersFromOverlay;
    const handleCloseButtonClick = () => {
        props.onClose();
    }
    const [selectedTypes, setSelectedTypes] = useState(props.states.selectedTypes);
    const [maxPrice, setMaxPrice] = useState(props.states.maxPrice);
    const [sort, setSort] = useState(props.states.sort);
    getFiltersFromOverlay(selectedTypes, maxPrice, sort);
    console.log(props.states)

    return (
        <div className="backgroundOverlay">
            <div className="overlayPage">
                <h1>Filtern und Sortieren</h1>
                <button className="closeButton" onClick={handleCloseButtonClick}>x</button>
                <div className="filterSection">
                    <div className="filter-item">
                        <h2>Kategorien</h2>
                        {props.furnitureTypes().map((type) => {
                            return (
                                <div className="inputItem">
                                    <input
                                        key={type}
                                        type="checkbox"
                                        className="custom-checkbox"
                                        id={type}
                                        value={type}
                                        checked={selectedTypes.includes(type)}
                                        onChange={(e) => {
                                            if (selectedTypes.includes(e.target.value)) {
                                                setSelectedTypes((oldValues) => oldValues.filter((item) => item !== e.target.value))
                                            } else {
                                                setSelectedTypes([...selectedTypes, e.target.value])
                                            }
                                        }}
                                    ></input>
                                    <label htmlFor={type}>{type}</label>
                                </div>
                            )
                        })}
                    </div>
                    <div className="filter-item">
                        <h2>Preis</h2>
                        <div className="inputItem">
                            <div className="sliderContainer">
                                <input className="slider"
                                    type="range" min={0}
                                    max={props.highestPrice}
                                    defaultValue={maxPrice ?? props.highestPrice}
                                    onChange={(e) => {
                                        setMaxPrice(e.target.value)
                                    }}
                                />
                                <span>{`bis ${insertDot(maxPrice)} €`}</span>
                            </div>
                        </div>
                    </div>
                    <div className="filter-item">
                        <h2>Sortieren</h2>
                        <div className="inputItem">
                            <input type="radio" id="asc" value="asc" name="Preis" onChange={() => setSort('asc')} />
                            <label htmlFor="asc">aufsteigend</label>
                        </div>
                        <div className="inputItem">
                            <input type="radio" id="desc" value="desc" name="Preis" onChange={() => setSort('desc')} />
                            <label htmlFor="desc">absteigend</label>
                        </div>
                    </div>
                </div>
                <div className="centerSection">
                    <button className="load-more-button" onClick={handleCloseButtonClick}>Anwenden</button>
                </div>
            </div>
        </div>
    )
}

export default FilterOverlay;