import { Route, Routes } from "react-router-dom";
import Product from "./pages/Product/Product";
import Products from "./pages/Products/Products";
import Home from "./pages/Home/Home";
import Header from "./components/Header";
import Footer from "./components/Footer";
import "./app.scss";
import React, { useContext, useEffect, useState } from "react";
import {useNavigate} from 'react-router-dom';
import Privacy from "./pages/Privacy";
import CookieConsent from "react-cookie-consent";
import ReactGA from 'react-ga4';
import usePageTracking from "./usePageTracking";
import Change from "./pages/Home/Change";

export const Context = React.createContext();

function App() {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  usePageTracking();

  return (
    <div className="app">
      <div>
      </div>
      <Context.Provider value={[searchTerm, setSearchTerm]}>
      <Routes>
        <Route
          path="/change"
          element={
            <>
              <Change />
            </>
          }
        />
        <Route
          path="/"
          element={
            <>
              <Home />
            </>
          }
        />
        <Route
          path="/products/:id"
          element={
            <>
              <Header />
              <Products />
              <Footer />
            </>
          }
        />
        <Route
          path="/product/:id"
          element={
            <>
              <Header />
              <Product />
              <Footer />
            </>
          }
        />
        <Route
          path="/privacy-policy"
          element={
            <>
              <Header />
              <Privacy />
              <Footer />
            </>
          }
        />
      </Routes>
      <CookieConsent 
      debug={false}
      cookieName="privacy"
      style={{ background: "#2B373B" }}
      buttonStyle={{fontSize: "15px", color: "white", background: "green", borderRadius: "5px", padding: "10px", margin: "10px" }}
      declineButtonStyle={{fontSize: "15px", color: "white", background: "red", borderRadius: "5px", padding: "10px", margin: "10px" }}
      expires={150}
      buttonText="Ja"
      enableDeclineButton
      declineButtonText="Nein"
      onDecline={() => {
      }}
      onAccept={() => {
        ReactGA.initialize('G-3W1E2QKH25');
      }}
      >Dürfen wir Cookies verwenden?</CookieConsent>
      </Context.Provider>
    </div>
  );
}

export default App;
