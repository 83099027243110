import './CardForPost.scss';

const CardForPost = (props) => {

    return (
        <div className='card-for-post'>
            <div>
                <h1>{props.post.title}</h1>
                <p>{props.post.description}</p>
            </div>
        </div>
    )
}
export default CardForPost;