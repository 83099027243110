import FeaturedProducts from "../../components/FeaturedProducts";
import "./Home.scss";
import Categories from "../../components/Categories";
import Contact from "../../components/Contact";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { useEffect, useState } from "react";
import Slider from "../../components/Slider";
import Posts from "../../components/Posts";
import ProductSlider from "../../components/ProductSlider";

const Home = () => {
    //Variable and Element explanations: 
    //Use posts array to write messages to the home page. It should be used to communicate with website visitors
    //Use Special_Offers array to add specific price reduced items to the home page
    //Use FURNITURE_COLLECTION_DESCRIPTION contains texts about the collection and should not be changed unless the user wants to do so explicitly
    //Use BACKGROUND_IMAGES to add background images to the home page   
    //<FeaturedProducts> is a component that displays a collection of furniture which is popular
    //<Categories> is a component that displays a collection of categories (i.e. Sofas, Chairs, Tables, etc.)


    //Functions
    const getRandomNumber = (array) => {
        return Math.floor(Math.random() * array.length);
    };

    const posts = [
        {
            type: 'text',
            title: 'Neues Angebot!',
            description: 'Jetzt 20% Rabatt auf alle Sofas',
        },
        {
            type: 'image',
            imageUrl: 'https://example.com/image.jpg',
        },
    ];

    //Use Special_Offers array to add reduced items to the home page
    const Special_Offers = require('../../mock_data/SpecialOffers.json');

    // This contains only standard information about the furniture collection we sell
    const HOMEPOET_DESCRIPTION = [
        "Du liebst die Natur und legst auch viel Wert auf nachhaltige und natürliche Möbel? NATURA HOME ist wie für dich gemacht. Hier findest du zeitlose und exklusive Massivholzmöbel, die verantwortungs- und umweltbewusst hergestellt werden. Mit NATURA HOME wird dein Zuhause zum harmonisch, einzigartig und warm eingerichteten Wohlfühl- und Rückzugsort für dich und deine Liebsten.",
        "Für unsere Massivholzmöbel verwenden wir ausschließlich zertifizierte Hölzer (PEFC & FSC). Das Material der Handelsklasse 1A stammt aus nachhaltiger Waldbewirtschaftung in Mittel- und Nordeuropa. Wir legen großen Wert auf besonders hochwertige Verarbeitung nach alter Handwerkstradition und verwenden langlebige Beschläge von führenden Herstellern.",
    ];

    // This contains only the background images for the home page
    const BACKGROUND_IMAGES = [
        'https://de-emverbund-spryker-prod-public.s3.eu-central-1.amazonaws.com/1/3/c/d/13cdb12b305cd3776e6874dbdcec24fededc0d95_na_Sofa_Denver_naturel_masterbild_108731_large.jpeg',
        // 'https://de-emverbund-spryker-prod-public.s3.eu-central-1.amazonaws.com/d/8/f/2/d8f261fe47a24a1783832519ea9c672acadf21c8_na_Freischwinger_Cambridge_Cognac_freisteller_1_105972.jpg',
        'https://de-emverbund-spryker-prod-public.s3.eu-central-1.amazonaws.com/1/9/5/f/195f77a47ae102a960ad1963b84aa1ea004056c2_na_Bett_Baltimore_freisteller_2_106977.jpg'
    ];

    //Hooks

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.toggle("show", entry.isIntersecting);
                } else {
                    entry.target.classList.remove("show");
                }
            });
        });
        const animatedTruck = document.querySelectorAll(".shipping_animation");
        animatedTruck.forEach((truck) => {
            observer.observe(truck);
        });
    }, []);
    return (
        <div className="home">
            <div className="parallex_wrapper">
                <Header></Header>
                <div className="parallax">
                    <div className="fg">
                        <img src="https://emv-va.de/files/Categories/60d31b88ba2ce/naturahome_ohneclaim.png" />
                    </div>
                    <img className="bg" src={BACKGROUND_IMAGES[getRandomNumber(BACKGROUND_IMAGES)]} alt="" />
                </div>
                <div className="layerimage">
                    <div className="repeatingImage">
                        <img src={process.env.PUBLIC_URL + '/images/forest.png'} alt="Forest" />
                        <img src={process.env.PUBLIC_URL + '/images/forest.png'} alt="Forest" />
                    </div>
                    <div className="backgroundsheet">
                        
                        <Posts posts={posts} />
                        <FeaturedProducts type='Natura Home' description={HOMEPOET_DESCRIPTION[0]} />
                        {/* <Slider offers={Special_Offers} /> */}
                        <ProductSlider items={Special_Offers}/>
                        <Categories />
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    )
}

export default Home