import { useState, useContext, useEffect } from "react";
import { Context } from "../App";
import { useNavigate } from "react-router-dom";
import "./SearchBar.scss";
import ReactGA from 'react-ga4';
import { Mixpanel } from "../Mixpanel_Wrapper";
import { getCookieConsentValue } from "react-cookie-consent";

const SearchBar = () => {
    const [currentSearchValue, setCurrentSearchValue] = useState('');
    const [searchInput, setSearchInput] = useContext(Context);
    const navigate = useNavigate();

    useEffect(() => {
        if (searchInput === "") {
            setCurrentSearchValue("");
        }
    }, [searchInput])

    const handleChange = (e) => {
        e.preventDefault();
        setCurrentSearchValue(e.target.value);
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            setSearchInput(currentSearchValue);
            navigate(`/products/search`)
            if (getCookieConsentValue("privacy") === "true") {
                Mixpanel.track("Search", {
                    "search_term": currentSearchValue,
                });
            } 
            ReactGA.event({
                category: "Search",
                action: "search_input",
                label: currentSearchValue, // optional
                // value: 99, // optional, must be a number
                // nonInteraction: true, // optional, true/false
                // transport: "xhr", // optional, beacon/xhr/image
            });
        }
    }

    return (
        <input className="search-bar"
            type="text"
            // change placeholder to search icon when media screen is small
            placeholder="Artikel suchen"
            onChange={handleChange}
            value={currentSearchValue}
            onKeyDown={handleKeyDown}
        />
    )

}

export default SearchBar;