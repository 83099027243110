import { getCookieConsentValue } from "react-cookie-consent";
import { Mixpanel } from "./Mixpanel_Wrapper";

class TimeOnPageTracker {
    constructor() {
        this.startTime = null;
        this.endTime = null;
    }

    startTracking() {
        this.startTime = new Date();
    }

    stopTracking(pagename) {
        this.endTime = new Date();
        const timeSpent = this.endTime - this.startTime;
        if (timeSpent > 200) {
            if (getCookieConsentValue("privacy") === "true") {
                Mixpanel.track("Time Spent On Page", {
                    "page": pagename,
                    "time_spent": timeSpent,
                });
            }
        }
    }
}

export default TimeOnPageTracker;
