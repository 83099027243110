import './Card.scss';
import { Link } from 'react-router-dom';

const Card = ({ item }) => {
    let productLink = "";
    let brand = item.brand;
    if (item.title === "" || item.price.includes('Mehr von')) {
        productLink = "/products/wohnen";
    } else {
        productLink = `/product/${item.id}`;
    }
    if (brand === undefined) {
        brand = "Natura Home"
    }
    return (
        <Link className="link" to={productLink}>
            <div className="card">
                <div className="image">
                    {item.isPopular && <span>Popular</span>}
                    <img src={item.image} alt="" className="mainImage" />
                    {/* <img src="" alt="" className="secondImage" /> */}
                </div>
                {
                    productLink === "/products/wohnen" ? <div></div> : <div className='item-title'><h3>{brand}</h3> <h2>{item.title}</h2></div>
                }
                {
                    productLink === "/products/wohnen" ?
                        <div className='moreOf'>
                            <h3>{item.price} {item.title}</h3>

                        </div> :
                        <div className="prices">
                            <h3 className="price">{item.price}</h3>
                        </div>
                }

            </div>
        </Link>

    )
}

export default Card;