import React from 'react';
import './Posts.scss';
import { useEffect, useState } from 'react';
import CardForPost from './CardForPost';

const Posts = (props) => {
    return (
        <div className='posts-container'>
            {props.posts.map((post) => (
                post.type === 'text' ? (
                    <CardForPost post={post} key={post.title} />
                ) : null
            ))}
        </div>
    );
}
export default Posts;