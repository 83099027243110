import './Categories.scss'
import { Link } from 'react-router-dom'

const DUMMY_CATEGORY_IMAGES = 
    {
        bed: 'https://de-emverbund-spryker-prod-public.s3.eu-central-1.amazonaws.com/9/1/8/4/918428d6e467088345dc55b8bf0a0e6def7c2299_na_Bett_Baltimore_detailbild_1_106977.jpg',
        tische_stuehle: 'https://a.storyblok.com/f/150457/770x440/c675ddc890/esszimmer-klassisch-natura-home-bakersfield-massivholz-esstisch-polsterstuhl-beige-highboard-ml-jpg.jpg',
        sofas_sessel: 'https://de-emverbund-spryker-prod-public.s3.eu-central-1.amazonaws.com/9/9/b/7/99b71fa436545565cb8e650a28ed0234fd542046_na_Sessel_Aberdeen_Moss_freisteller_2_109563.jpg',
        kommoden_sideboards: 'https://de-emverbund-spryker-prod-public.s3.eu-central-1.amazonaws.com/a/1/0/a/a10a362f0cdaa00098d588c57407373f22f5b42e_natura_kommode_baltiomore_milieubild.jpg',
        wohnzimmertische: 'https://de-emverbund-spryker-prod-public.s3.eu-central-1.amazonaws.com/b/c/a/0/bca042ebc1dc565ee3a04103be168a3f421b9873_na_Couchtisch_Farmingdale_freisteller_1_108782.jpg',
        schranke: 'https://de-emverbund-spryker-prod-public.s3.eu-central-1.amazonaws.com/0/b/9/4/0b94e8bb2d2c0190f1b779c8cf82d66341d47965_Global_Family_Schwebet__renschrank_Viana_detailbild_1_106169.jpg'
    }


const Categories = () => {
    return (
        <div className="categories">
            <div className="col">
                <div className="row">
                    <img src={DUMMY_CATEGORY_IMAGES.bed} alt="" />
                    <button>
                        <Link to="/products/schlafen" className="link">Betten</Link>
                    </button>
                </div>
                <div className="row">
                    <img src={DUMMY_CATEGORY_IMAGES.tische_stuehle} alt="" />
                    <button>
                        <Link to="/products/essen" className="link">Tische & Stühle</Link>
                    </button>
                </div>
            </div>
            <div className="col">
                <div className="row">
                    <img src={DUMMY_CATEGORY_IMAGES.sofas_sessel} alt="" />
                    <button>
                        <Link to="/products/wohnen" className="link">Sofas & Sessel</Link>
                    </button>
                </div>
            </div>
            <div className="col col-l">
                <div className="row">
                    <div className="col">
                        <div className="row">
                            <img src={DUMMY_CATEGORY_IMAGES.kommoden_sideboards} alt="" />
                            <button>
                                <Link to="/products/wohnen" className="link">Kommoden & Sideboards</Link>
                            </button>
                        </div>
                    </div>
                    <div className="col">
                        <div className="row">
                            <img src={DUMMY_CATEGORY_IMAGES.wohnzimmertische} alt="" />
                            <button>
                                <Link to="/products/wohnen" className="link">Wohnzimmertische</Link>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <img src={DUMMY_CATEGORY_IMAGES.schranke} alt="" />
                    <button>
                        <Link to="/products/schlafen" className="link">Schränke</Link>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Categories