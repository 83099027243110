import './List.scss'
import Card from '../components/Card'

const List = (props) => {
    //Variables
    const items = props.items;

    return (
        <div className='list'>
            {items.map((item) => (
                <Card item={item} key={item.id} />
            ))
            }
        </div>
    )
}

export default List