import './Product.scss'
import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import PDFViewer from './PDFViewer';
import { Mixpanel } from '../../Mixpanel_Wrapper';
import TimeOnPageTracker from '../../timeOnPageTracker.js';

const naturaHomeMockData = require('../../mock_data/NaturaHome.json');
const gomabMockData = require('../../mock_data/Gomab.json');
const specialOffers = require('../../mock_data/SpecialOffers.json');

const Product = () => {
    const location = useLocation();
    const timeTracker = new TimeOnPageTracker();
    const itemIsASpecialOffer = checkForSonderangebot();

    if (itemIsASpecialOffer) {
        var item = specialOffers.find((item) => item.id.toString() === removeBeforeLastSlash(location.pathname));
        console.log(item)
    }
    else {
        var item = naturaHomeMockData.find((item) => item.id.toString() === removeBeforeLastSlash(location.pathname));
        if (!item) {
            item = gomabMockData.find((item) => item.id.toString() === removeBeforeLastSlash(location.pathname));
        }
    }
    Mixpanel.track('Product View', {
        'Product Title': item.title,
        'Product Brand': item.brand,
    })
    //Functions 
    function removeBeforeLastSlash(str) {
        const lastIndex = str.lastIndexOf('/');
        if (lastIndex === -1) {
            return str;
        } else {
            return str.substr(lastIndex + 1);
        }
    }

    function checkForSonderangebot() {
        return location.pathname.includes('sonderangebot');
    }

    //Hooks
    const [selectedImage, setSelectedImage] = useState(0);
    useEffect(() => {
        timeTracker.startTracking();
        window.addEventListener('beforeunload', null);
        return () => {
            window.removeEventListener('beforeunload', timeTracker.stopTracking(item.title));
        };
    }, [])

    return (
        <div>
            {item ?
                <div className='product'>
                    <div className="left">
                        <div className="imagesContainer">
                            <div className="images">
                                {item.images &&
                                    item.images.map((image, index) => {
                                        return (
                                            <img src={image} key={index} alt="" onClick={() => setSelectedImage(index)} />
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className="mainImage">
                            {item.images ?
                                <img src={item.images[selectedImage]} alt="" />
                                :
                                <img src={item.image} alt="" />
                            }
                        </div>
                    </div>
                    <div className="right">
                        <h3 className="brand">{item.brand}</h3>
                        <h1>{item.title}</h1>
                        <span className='price'>{item.price}</span>
                        <div>{item.description && (item.brand != "Sonderangebot" ? item.description.map((paragraph, index) => {
                            return (
                                <p key={index}>
                                    <span>{paragraph}</span>
                                </p>
                            )
                        }) : <span>{item.description}</span>)}</div>
                        <div className="info">
                            {/* <span>{item.color &&
                                `Farbe: ${item.color}`}</span> */}
                            <span>{item.variants &&
                                `Varianten: ${item.variants}`}</span>
                            <span>{item.size &&
                                `Maße: ${item.size}`}</span>
                        </div>
                        <hr />
                    </div>
                </div>

                : <div className='productNotFound'>
                    <h1>Scheint so, als hätten wir dieses Produkt nicht (mehr).</h1>
                    <h2>...aber vielleicht haben wir noch Eins in unserer Ausstellung oder finden ein ähnliches Modell:</h2>
                    <h1>038355 68811</h1>
                </div>}
            {!itemIsASpecialOffer && (
                <>
                    <PDFViewer pdfLink={item.pdfLink} className="pdfViewer" />
                </>
            )}
        </div>
    )
}

export default Product