import './Products.scss'
import List from '../../components/List'
import { useParams, useLocation } from 'react-router-dom';
import { useState, useEffect, useContext } from 'react';
import { Context } from '../../App';
import FilterOverlay from '../../components/FitlerOverlay';
import { Filter } from '@mui/icons-material';

const Products = () => {
    //Variables
    const [searchTerm, setSearchTerm] = useContext(Context);
    const [items, setItems] = useState([]);
    const [itemsToShowCount, setItemsToShowCount] = useState(25);
    const [totalItemsCount, setTotalItemsCount] = useState(100);
    const [showFilterOverlay, setShowFilterOverlay] = useState(false);
    const loadmoreItems = () => {
        setItemsToShowCount((prevValue) => prevValue + 25);
    }
    const location = useLocation();
    const naturaHomeMockData = require('../../mock_data/NaturaHome.json');
    const gomabMockData = require('../../mock_data/Gomab.json');
    const productCategoriesAndTypes = [
        {
            "Wohnen": [
                "Sofas & Couches", "Kommoden & Sideboards", "Wohnzimmertische", "Garderoben", "Regale", "Sessel", "Dekorationen"
            ],
            "Schlafen": [
                "Betten", "Kleiderschränke", "Nachttische"
            ],
            "Essen": [
                "Esstische", "Stühle"
            ], "Alle": [
                "Sofas & Couches", "Kommoden & Sideboards", "Wohnzimmertische", "Garderoben", "Regale", "Sessel", "Dekorationen", "Betten", "Kleiderschränke", "Nachttische", "Esstische", "Stühle"
            ]
        }
    ]

    //Functions

    const showFiltersOnMobile = () => {
        if (window.innerWidth < 600) {
            setShowFilterOverlay(true);
        }
    }

    //Katalog Download
    const onButtonClick = () => {
        // using Java Script method to get PDF file
        fetch(process.env.PUBLIC_URL + '/Scan-Moebel-Katalog.pdf').then(response => {
            console.log(process.env.PUBLIC_URL + '/Scan-Moebel-Katalog.pdf');
            response.blob().then(blob => {
                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(blob);
                // Setting various property values
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = 'Scan-Moebel-Katalog.pdf';
                alink.click();
            })
        })
    }
    const highestPrice = () => {
        let highestPrice = 0;
        naturaHomeMockData.map((item) => {
            let price = removeCurrency(item.price);
            if (price > highestPrice) {
                highestPrice = price;
            }
            return highestPrice;
        })
        return highestPrice;
    }

    function removeCurrency(priceString) {
        let cleanedString = priceString.replace(/€|\./g, '').replace(',', '').slice(0, -3);

        let priceInteger = parseInt(cleanedString);

        return priceInteger;
    }

    function insertDot(input) {
        if (typeof input !== 'string') {
            //   throw new TypeError('Input muss ein String sein!');
            input = input.toString();
        }

        const numbers = input.match(/\d/g);
        const numberString = numbers ? numbers.join('') : '';

        if (numberString.length !== 4) {
            return input;
        }
        return `${numberString.slice(0, 1)}.${numberString.slice(1)}`;
    }

    const allTypes = function getAllTypes() {
        let types = [];
        for (const obj in productCategoriesAndTypes) {
            for (const category in productCategoriesAndTypes[obj]) {
                if (category === "Alle") {
                    for (const type of productCategoriesAndTypes[obj][category]) {
                        types.push(type);
                    }
                }
            }
        }
        return types;
    }

    //Get the location pathname and check if it contains 'wohnen' or 'schlafen' or 'essen'
    function checkCategory() {
        // let category = '';
        if (location.pathname.includes('wohnen')) {
            // category = 'Wohnen';
            setAmbiente_image('./images/ambiente_wohnen.jpeg');
            return productCategoriesAndTypes[0].Wohnen;
        } else if (location.pathname.includes('schlafen')) {
            // category = 'Schlafen';
            setAmbiente_image('./images/ambiente_schlafen.jpeg');
            return productCategoriesAndTypes[0].Schlafen;
        } else if (location.pathname.includes('essen')) {
            // category = 'Essen';
            setAmbiente_image('./images/ambiente_essen.jpeg');
            return productCategoriesAndTypes[0].Essen;
        } else {
            // category = 'Suche';
            setAmbiente_image('./images/ambiente_essen.jpeg');
            return productCategoriesAndTypes[0].Alle;
        }
    }

    function filterItems() {
        let filteredItems = [];
        naturaHomeMockData.map((item) => {
            // console.log(item.title.toLowerCase() + " vs " + searchTerm.toLowerCase() )
            if (removeCurrency(item.price) <= maxPrice &&
                (item.title.toLowerCase().includes(searchTerm.toLowerCase()))
            ) {
                filteredItems.push(item);
            }
            return filteredItems;
        })
        // gomabMockData.map((item) => {
        //     if (removeCurrency(item.price) <= maxPrice &&
        //         (item.title.toLowerCase().includes(searchTerm.toLowerCase()))
        //     ) {
        //         filteredItems.push(item);
        //     }
        //     return filteredItems;
        // })
        //Check if natura home items.type are in selectedTypes
        filteredItems = filteredItems.filter((item) => selectedTypes.includes(item.type));
        if (sort === 'asc') {
            filteredItems.sort((a, b) => removeCurrency(a.price) - removeCurrency(b.price));
        } else if (sort === 'desc') {
            filteredItems.sort((a, b) => removeCurrency(b.price) - removeCurrency(a.price));
        }
        setTotalItemsCount(filteredItems.length);
        const itemsToShow = filteredItems.slice(0, itemsToShowCount);
        setItems(itemsToShow);
    }

    //Hooks
    const [ambiente_image, setAmbiente_image] = useState('./images/wohnen_ambiente.jpeg');
    const categoryId = useParams().categoryId;
    const [maxPrice, setMaxPrice] = useState(highestPrice);
    const [sort, setSort] = useState('asc');
    const [selectedTypes, setSelectedTypes] = useState([]);
    useEffect(() => {
        setSelectedTypes(() => checkCategory());
    }, [location]);
    useEffect(() => {
        filterItems();
    }, [itemsToShowCount, selectedTypes, maxPrice, sort, searchTerm]);
    useEffect(() => {
        setItemsToShowCount(25);
    }, [selectedTypes, searchTerm]);

    const onCloseFilterOverlay = () => {
        setShowFilterOverlay(false);
    }

    const handleOverlayFilters = (categories, price, ascOrDesc) => {
        setSelectedTypes(categories);
        setMaxPrice(price);
        setSort(ascOrDesc);
    }

    return (
        <>
            {showFilterOverlay ? <FilterOverlay states={{ selectedTypes: selectedTypes, maxPrice: maxPrice, sort: sort }} totalResults={itemsToShowCount} getFiltersFromOverlay={handleOverlayFilters} allItems={naturaHomeMockData} furnitureTypes={allTypes} highestPrice={highestPrice()} onClose={onCloseFilterOverlay} formattingFunctions={{ insertDot: insertDot, removeCurrency: removeCurrency }}/> :
                <div className='products'>
                    <div className="left">
                        <div className="filterItem">
                            <h2 onClick={showFiltersOnMobile}>Kategorien</h2>
                            {window.innerWidth > 600 && (
                                <>
                                    {allTypes().map((type) => {
                                        return (
                                            <div className='inputItem' key={type}>
                                                <input
                                                    className='custom-checkbox'
                                                    type='checkbox'
                                                    id={type}
                                                    value={type}
                                                    checked={selectedTypes.includes(type)}
                                                    onChange={(e) => {
                                                        if (selectedTypes.includes(e.target.value)) {
                                                            setSelectedTypes(selectedTypes.filter((item) => item !== e.target.value));
                                                        } else {
                                                            setSelectedTypes([...selectedTypes, e.target.value]);
                                                        }
                                                    }}
                                                />
                                                <label htmlFor={type}>{type}</label>
                                            </div>
                                        );
                                    })}
                                </>
                            )}
                        </div>
                        <div className="filterItem">
                            <h2 onClick={showFiltersOnMobile}>Preislimit</h2>
                            {window.innerWidth > 600 && (
                                <>
                                    <div className="inputItem">
                                        <div className='sliderContainer'>
                                            <input className='slider' type="range" min={0} max={highestPrice()}
                                                defaultValue={maxPrice ?? highestPrice()} onChange={(e) => setMaxPrice(e.target.value)} />
                                            <span>{`bis ${insertDot(maxPrice)} €`}</span>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                        <div className="filterItem">
                            <h2 onClick={showFiltersOnMobile}>Sortieren</h2>
                            {window.innerWidth > 600 && (
                                <>
                                    <div className="inputItem">
                                        <input type="radio" id='asc' value="asc" name='Preis' onChange={() => setSort('asc')} />
                                        <label htmlFor="asc">aufsteigend</label>
                                    </div>
                                    <div className="inputItem">
                                        <input type="radio" id='desc' value="desc" name='Preis' onChange={() => setSort('desc')} />
                                        <label htmlFor="asc">absteigend</label>
                                    </div>
                                </>
                            )}
                        </div>
                        {window.innerWidth > 600 && (
                            <>
                                <a className="catalog-download" onClick={onButtonClick}>Katalog Download</a>
                                <a className="catalog-download-subtext">Auf Wunsch schicken wir dir den aktuellen Katalog auch gerne kostenlos per Post.</a>
                            </>
                        )}
                    </div>
                    <div className="right">
                        {window.innerWidth > 600 && (
                            <>
                                <img className='categoryImage' src={ambiente_image} alt={ambiente_image} />
                            </>
                        )}
                        <h3>{totalItemsCount} Artikel</h3>
                        <List categoryId={categoryId} items={items}></List>
                        {itemsToShowCount < totalItemsCount &&
                            <button className="load-more-button" onClick={loadmoreItems}>Mehr laden</button>
                        }
                    </div>
                </div>
            }
        </>
    )
}

export default Products