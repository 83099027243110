import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from 'react-ga4';
import { Mixpanel } from "./Mixpanel_Wrapper";
import { getCookieConsentValue } from "react-cookie-consent";
import TimeOnPageTracker from "./timeOnPageTracker";

const usePageTracking = () => {
  const location = useLocation();
  const timeTracker = new TimeOnPageTracker();
  useEffect(() => {
    // Check if pathname does not contain /product/ 
    if (!location.pathname.includes("/product/")) {
      timeTracker.startTracking();
    }

    if (getCookieConsentValue("privacy") === "true") {
      
      Mixpanel.track("Custom Page View", {
        "page": location.pathname,
      });
      ReactGA.send({ hitType: "pageview", page: location.pathname });
    } 
    //cleanup 
    return () => {
      if (!location.pathname.includes("/product/")) {
        timeTracker.stopTracking(location.pathname);
      }
    };
  }, [location]);
};

export default usePageTracking;