var mixpanel = require('mixpanel');

mixpanel = mixpanel.init("c12a9bb7f4d771874153901424656a8a", {debug: true, track_pageview: true, persistence: 'localStorage', host: "api-eu.mixpanel.com",});


let env_check = process.env.NODE_ENV === 'production';
//set to true for testing
// let env_check = true;

let actions = {
  identify: (id) => {
    if (env_check) mixpanel.identify(id);
  },
  alias: (id) => {
    if (env_check) mixpanel.alias(id);
  },
  track: (name, props) => {
    if (env_check) mixpanel.track(name, props);
  },
  people: {
    set: (props) => {
      if (env_check) mixpanel.people.set(props);
    },
  },
};

export const Mixpanel = actions;