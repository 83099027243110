import './ProductSlider.scss';
import Card from './Card';

const ProductSlider = (props) => {
    return (
        <div>
            <h2>Aktuelle Sonderangebote</h2>
        <div className="productSlider">

            <div className="bottom">
                {props.items.map((item) => (
                    <Card item={item} key={item.id} />
                ))}
            </div>
        </div>
        </div>
    )
}

export default ProductSlider;
