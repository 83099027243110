import React from 'react'
import './PDFViewer.scss'

const PDFViewer = (props) => {
    function onButtonClick() {
        window.open(props.pdfLink, '_blank');
    }
    return (
        <div className="pdfViewer">
            <h1>Alle Informationen zur Serie</h1>
            <h2>Material und Pflege, Modellvarianten, Typen- und Preisliste</h2>
            <div className='pdfContainer'>
                <object data={props.pdfLink} type="application/pdf" width="100%" height="100%">
                    <div className="buttonContainer">
                        <button className='load-more-button' onClick={onButtonClick}>Zur PDF</button>
                    </div>
                </object>
            </div>
        </div>
    )
}

export default PDFViewer