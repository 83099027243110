import './FeaturedProducts.scss';
import Card from './Card';

const FeaturedProducts = ({ type, description }) => {
    let naturaHomeData = require('../mock_data/NaturaHome.json');
    let gomabData = require('../mock_data/Gomab.json');

    let productsToDisplay = type === "Natura Home" ? naturaHomeData.slice(0, 7) :
        type === "Gomab" ? gomabData.slice(0, 7) : [];

    return (
        <div className="featuredProducts">
            <h2>Die Natura Home Kollektion</h2>
            <div className="top">
                {type === "Natura Home" ?
                    <img src={process.env.PUBLIC_URL + '/images/natura_home_2019_no_claim.jpeg'} alt="Pure Natur Logo" /> :
                    type === "Gomab" ?
                        <img src={process.env.PUBLIC_URL + 'images/gomab-green.png" alt="Pure Natur Logo'} /> :
                        <h1>{type}</h1>
                }
                <p>{description}</p>
            </div>
            <div className="bottom">
                {productsToDisplay.map((item) => (
                    <Card item={item} key={item.id} />
                ))}
                {productsToDisplay.length < 8 && <Card item={
                    {
                        id: 0,
                        title: "",
                        price: "",
                        image: process.env.PUBLIC_URL + '/images/arrow-right-small.png',
                    }
                } key={'see more'} />}
            </div>
        </div>
    )
}

export default FeaturedProducts;
